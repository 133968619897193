<template>
  <div :class="isqpisboot ? 'wbobye zhadminadd newoqcuser' : 'zhadminadd newoqcuser'" style="min-width: 910px;">
    <div style="text-align: right; z-index: 1000;">
      <el-button size="small" style="text-align: right;" type="primary" @click="isqpisboot = !isqpisboot">{{ isqpisboot ?
        $t('All.取消全屏') : $t('All.全屏') }}</el-button>
      <el-button size="small" style="text-align: right;" type="primary" @click="Formoqcadd(form, 4)">{{ $t('All.提交')
      }}</el-button>
      <el-button size="small" style="text-align: right;" type="primary" @click="Formoqcadd(form, 3)">{{ $t('All.暂存')
      }}</el-button>
    </div>
    <h2 style="text-align: center;">{{$t('All.年益实业股份有限公司')}}</h2>
    <h3 style="text-align: center;">{{$t('All.OQC出货报告')}}</h3>
    <h4 style="text-align: center;">{{$t('All.系统单号')}}：{{ form.Numbers }}</h4>
    <div style=" padding: 10px; text-align: center; line-height: 2vw;">
      <table border="1" style="table-layout: fixed; word-break: break-all; word-wrap: break-word;width:100%;" align="center" cellpadding="10">
        <tr>
          <td style="width: 230px;">
            <p style="margin: 0;line-height: 1.2;width: 230px;">{{$t('All.出货日期')}}<br>Shipping date</p>
          </td>
          <td style="width: 200px;">
            <p style="margin: 0;line-height: 1.2;">{{$t('All.客户')}}<br>Customer</p>
          </td>
          <td>
            <p style="margin: 0;line-height: 1.2;">{{$t('All.参考文件')}}<br>Reference documents</p>
          </td>
          <td style="width: 202px;">PL NO.</td>
        </tr>
        <tr>
          <td class="tdd" style="max-width: 230px;">
            <!--<el-input v-model="form.Chdate"></el-input>-->
            <el-date-picker v-model="form.Chdate" value-format="yyyy-MM-dd" style="max-width: 230px;" type="date" :placeholder="$t('All.选择出货日期')">
            </el-date-picker>
          </td>
          <td class="tdd"><el-input v-model="form.Customer"></el-input></td>
          <td class="tdd" v-if="!form.Fileroute">
            <el-button size="mini" type="primary"  @click="yzdialogVisible = true" >{{ $t('All.参考文件') }}</el-button>
          </td>
          <td class="tdd" v-if="form.Fileroute">
            <el-tooltip effect="dark" :content="form.Reference" placement="top-start">
              <div style="overflow: hidden;white-space: nowrap;cursor: default;text-overflow: ellipsis;color: #006bda;">
                <span @click="fileopen()">{{ form.Reference }}</span>
                <i class="el-icon-error" style="padding-left: 10px; color: red;" @click="delfile()"></i>
              </div>
            </el-tooltip>
          </td>
          <td class="tdd"><span class="oqctest">{{ form.Plno }}</span></td>
        </tr>
      </table>
      <table border="1" style="table-layout: fixed; word-break: break-all; word-wrap: break-word;width:100%; margin-top:-2px;" align="center" cellpadding="10">
        <tr>
          <td style="width: 230px;">
            <p style="margin: 0;line-height: 1.2;">{{$t('All.抽箱基准')}}<br>Benchmark for container extraction</p>
          </td>
          <td class="tdd" colspan="1"><el-input v-model="form.Benchmark"></el-input></td>
        </tr>
      </table>
      <table border="1" style="table-layout: fixed; word-break: break-all; word-wrap: break-word;width:100%; margin-top:-2px;" align="center" cellpadding="10">
        <tr>
          <td>
            <p style="margin: 0;line-height: 1.2;">{{$t('All.品名')}}<br>Product</p>
          </td>
          <td style="width: 100px;">
            <p style="margin: 0;line-height: 1.2;">{{$t('All.出货数')}}<br>Quantity of shipments</p>
          </td>
          <td style="width: 100px;">
            <p style="margin: 0;line-height: 1.2;">{{$t('All.抽样箱数')}}<br>Sampling box quantity</p>
          </td>
          <td style="min-width: 150px;">
            <p style="margin: 0;line-height: 1.2;">{{$t('All.抽样箱号')}}<br>Sampling box number</p>
          </td>
          <td style="min-width: 150px;">
            <p style="margin: 0;line-height: 1.2;">{{$t('All.抽样重量')}}(KG)<br>Sampling weight</p>
          </td>
          <td style="min-width: 100px;">
            LOT No.
          </td>
          <td style="width: 60px;">
            <p style="margin: 0;line-height: 1.2;">{{$t('All.唛头包装封箱胶纸外箱标签')}}<br>Marking/Packaging/Sealing tape/Outer box label</p>
          </td>
          <td style="width: 100px;">
            <p style="margin: 0;line-height: 1.2;">{{$t('All.栈板')}}<br>Pallet</p>
          </td>
          <td style="width: 80px;">
            <p style="margin: 0;line-height: 1.2;">{{$t('All.判定')}}<br>Determine</p>
          </td>
        </tr>
        <tr v-for="(item,index) in form.Productlist" :key="index">
          <td style="width: 200px;">
            <span class="oqctest">{{ item.Productname }}</span>
          </td>
          <td style="width: 100px;">
            <span class="oqctest">{{item.Quantity}}({{ item.Cases }}/{{ item.Quantitybox }})</span>
          </td>
          <td class="tdd"><el-input v-model="item.Samplingnumber"></el-input></td>
          <td class="tdd">
            <el-input type="textarea" :rows="3" v-model="item.Cynumber"></el-input>
          </td>
          <td class="tdd">
            <el-input type="textarea" :rows="3" v-model="item.Weight"></el-input>
          </td>
          <td style="width: 100px;">
            <el-input v-model="item.Lot"></el-input>
          </td>
          <td style="width: 80px;">
            <el-select v-model="item.Concentrate" :placeholder="$t('All.请判定')">
                <el-option label="Pass" value="Pass"></el-option>
                <el-option label="Fail" value="Fail"></el-option>
            </el-select>
          </td>
          <td style="width: 100px;">
            <el-input v-model="item.Pallet"></el-input>
          </td>
          <td style="width: 60px;">
            <el-select v-model="item.Judge" :placeholder="$t('All.请判定')">
                <el-option label="Pass" value="Pass"></el-option>
                <el-option label="Fail" value="Fail"></el-option>
            </el-select>
          </td>
        </tr>
        <tr>
          <td>
            <p style="margin: 0;line-height: 1.2;">{{$t('All.出货照片')}}<br>Shipment photos</p>
          </td>
          <td colspan="8" style="text-align: left;">
            <Upload  v-if="form.Numbers" :Numbers="form.Numbers" :Type="1"></Upload>
          </td>
        </tr>
        <tr>
          <td>
            <p style="margin: 0;line-height: 1.2;">{{$t('All.备注')}}<br>Notes</p>
          </td>
          <td colspan="8" style="text-align: left;">
            <el-input type="textarea" :rows="3" v-model="form.Notes"></el-input>
          </td>
        </tr>
      </table>
      <table style="table-layout: fixed; word-break: break-all; word-wrap: break-word;width:100%;" align="center" cellpadding="10">
        <tr>
          <td>
            <p style="margin: 0;line-height: 1.2;">{{$t('All.品管主管')}}<br>Notes</p>
          </td>
          <td>{{ form.Managername }}</td>
          <td>
            <p style="margin: 0;line-height: 1.2;">{{$t('All.品管班长')}}<br>Quality control team leader</p>
          </td>
          <td>{{ form.Monitorname }}</td>
          <td>
            <p style="margin: 0;line-height: 1.2;">{{$t('All.检验员')}}<br>Inspector</p>
          </td>
          <td>{{ form.Username }}</td>
        </tr>
      </table>
    </div>
    <el-dialog
        :title="$t('All.参考文件引用')"
        :visible.sync="yzdialogVisible"
        width="500px"
        :before-close="handleClose" append-to-body>
    {{$t('All.文件编号')}}：<el-input style="width: 300px;border: 1px solid #DCDFE6 !important;border-radius: 5px;" v-model="Mz_Numbers"></el-input>
    <span slot="footer" class="dialog-footer">
      <el-button @click="yzdialogVisible = false">{{ $t('All.取消') }}</el-button>
      <el-button type="primary" @click="Filenumber(Mz_Numbers, form.Factory)">{{ $t('All.确认') }}</el-button>
    </span>
  </el-dialog>
  <el-dialog :title="$t('All.选择文件')" :visible.sync="filedialogVisible" width="900px" append-to-body :before-close="handleClose">
    <el-table :data="filedata" v-show="filedata.length > 0" stripe style="width: 100%" @row-click="rowdblclick">
        <el-table-column prop="Filenumber" :label="$t('All.文件编号')"></el-table-column>
        <el-table-column prop="Filename" :label="$t('All.文件名称')"></el-table-column>
        <el-table-column prop="Version" :label="$t('All.版本')"></el-table-column>
      </el-table>
      </el-dialog>
  </div>
</template>
<script>
import Upload from './Upload.vue'
import { mapMutations } from 'vuex'
export default {
  components: {
    Upload
  },
  data () {
    return {
      form: {},
      yzdialogVisible: false,
      isqpisboot: false,
      DetermineOptions: [{
        label: 'Pass',
        value: 'Pass'
      }, {
        label: 'Fail',
        value: 'Fail'
      }, {
        label: 'None',
        value: 'None'
      }],
      dynamicTags: [],
      Mz_Numbers: '',
      filedata: {},
      filedialogVisible: false,
      loadingInstance: null
    }
  },
  props: ['Addtab', 'RemoveTab', 'Renewtargename', 'Rendw'],
  created () {
    this.loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
    if (this.$store.state.OQCdata !== null) {
      this.form = this.$store.state.OQCdata
    }
  },
  mounted () {
    if (this.loadingInstance !== null) {
      this.loadingInstance.close()
    }
  },
  methods: {
    ...mapMutations(['OQCdatafun', 'ConductIs']),
    async handleRemove (file, fileList) {
      try {
        const { data: res } = await this.$http.post('/api/Zh/ImgDel', file)
        if (res.status !== 200) {
          return this.$message.error(this.$t('All.数据异常'))
        }
        for (let index = 0; index < this.fileList.length; index++) {
          if (this.fileList[index].name === file.name) {
            this.fileList.splice(index, 1)
            return this.$message.success(this.$t('All.删除成功'))
          }
        }
        this.$message.success(this.$t('All.删除成功'))
      } catch (error) {
        this.$message.error(error)
      }
    },
    handlePictureCardPreview (file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    handsuccess (response) {
      const data = {
        name: '',
        url: ''
      }
      if (response.status !== 200) return this.$message.error(this.$t('All.图片上传失败'))
      data.name = response.name
      data.url = response.url
      this.fileList.push(data)
    },
    async Formoqcadd (row, state) {
      if (this.form.Reference == null || this.form.Reference.length === 0) return this.$message.error(this.$t('All.请补齐参考文件在进行操作'))
      row.State = state
      const postdata = {
        Newoqc: row,
        UserData: this.$store.state.Login
      }
      const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      try {
        const { data: res } = await this.$http.post('/api/OQC/Formoqcadd', postdata)
        if (res.status !== 200) {
          loadingInstance.close()
          return this.$message.error(res.msg)
        }
        loadingInstance.close()
        this.$message.success(this.$t('All.提交成功'))
        this.OQCdatafun('')
        this.Addtab(this.$t('All.新OQC检验单'), 'NewOQCSeleItem')
        this.RemoveTab(this.$t('All.新OQC检验单') + '-' + row.Numbers)
        this.Rendw(this.$t('All.待审'), 'Examine', 'UserJyySele')
      } catch (error) {
        loadingInstance.close()
        this.$message.error(error)
      }
    },
    aqlfun (row) {
      var que = parseInt(row)
      if (que <= 4) {
        this.form.Aql = '100%'
        this.form.Samplingnumber = que
      } else
      if (que <= 12 && que >= 5) {
        this.form.Aql = '40%'
        this.form.Samplingnumber = Math.ceil(que * 0.4)
      } else
      if (que <= 30 && que >= 13) {
        this.form.Aql = '25%'
        this.form.Samplingnumber = Math.ceil(que * 0.25)
      } else
      if (que <= 50 && que >= 31) {
        this.form.Aql = '18%'
        this.form.Samplingnumber = Math.ceil(que * 0.18)
      } else
      if (que <= 100 && que >= 51) {
        this.form.Aql = '15%'
        this.form.Samplingnumber = Math.ceil(que * 0.15)
      } else
      if (que > 100) {
        this.form.Aql = '10%'
        this.form.Samplingnumber = Math.ceil(que * 0.1)
      }
    },
    async Filenumber (item, factory) {
      const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      try {
        const { data: res } = await this.$http.post(`/api/File/Filenumber?number=${item}&factory=${factory}`)
        if (res.status !== 200) {
          loadingInstance.close()
          this.ckwjisbool = false
          return this.$message.error(res.msg)
        }
        loadingInstance.close()
        this.filedata = res.Filedata
        this.filedialogVisible = true
        // this.ckwjiurl = 'http://120.195.84.42:5900/Filesystem/TCWS202309140058/1694677714973.PDF'
      } catch (error) {
        loadingInstance.close()
        this.$message.error(error)
      }
    },
    rowdblclick (row) {
      this.filedialogVisible = false
      this.yzdialogVisible = false
      this.form.Reference = row.Filename
      this.form.Fileroute = row.Fileroute
    },
    delfile () {
      this.$confirm(this.$t('All.确认解除文件引用'), this.$t('All.提示'), {
        confirmButtonText: this.$t('All.确认'),
        cancelButtonText: this.$t('All.取消'),
        type: 'warning'
      })
        .then(async () => {
          this.form.Reference = ''
          this.form.Fileroute = ''
        })
        .catch(() => {
        })
    },
    fileopen () {
      this.ConductIs('')
      this.$store.state.Url = ''
      this.$nextTick(() => { // 异步关闭
        setTimeout(() => {
          // 在延迟2秒后执行的代码
          this.$store.state.Url = this.$Upload + '/' + this.form.Fileroute
          this.ConductIs('ConductIs')
        }, 500)
      })
      // window.open(url, '_blank')
    },
    handleClose (done) {
      this.$confirm(this.$t('All.确认关闭'), {
        confirmButtonText: this.$t('All.确认'),
        cancelButtonText: this.$t('All.取消'),
        type: 'warning'
      })
        .then(_ => {
          done()
        })
        .catch(_ => {})
    }

  }
}
</script>
<style lang="less" scoped>.wbobye {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255);
}

.el-col {
  border: 1px solid;
  margin-right: -1px;
  margin-bottom: -1px;
  line-height: 4.5vw;
  font-size: 2vw;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.grid-content {
  min-height: 36px;
}

.bg-purple-light {}

.row-bg {
  background-color: #f9fafc;
}

.admin {
  border: 1px solid grey;
  border-top: 0px;
  padding-top: 15px;
  padding-left: 15px;
  text-align: left;
}

.oqctest {
  font-size: 14px;
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
}
.el-tag + .el-tag {
  margin-left: 10px;
  margin-top: 10px;
  }
  .button-new-tag {
    margin-top: 10px;
    margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
  }
  .input-new-tag {
    margin-top: 10px;
    width: 87px;
    margin-left: 10px;
    height: 30px;
    line-height: 30px;
    vertical-align: bottom;
    border: 1px solid #409eff;
    border-radius: 4px;
  }
</style>
